<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);

        return {
        doesAddOpen,
        doesEditOpen,
        doesDeleteOpen,
        };
    },
    page: {
    title: "Secteurs",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "IF & CTAF",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "IF & CTAF",
          active: true,
        },
      ],
      errors: [],
      users: [],
      if_ctaf: {},
      if_ctafs: [],
      if_id: "",
      ctaf_id: "",
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('if-ctafs'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.if_ctafs = data.data
      })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('ctaf_id', this.ctaf_id)
      formData.append('if_id', this.if_id)
      fetch(Helper.route('if-ctafs'), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.doesAddOpen = false
          this.if_ctafs.push(data.data)
          Swal.fire("IF & CTAF ajouter !", "IF & CTAF a été enregistré avec succès.", "success");
        } else {      
            console.log(Helper.getError(data.data, "user_id"));
            this.errors.if_id = Helper.getError(data.data, "if_id")
            this.errors.ctaf_id = Helper.getError(data.data, "ctaf_id")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('_method', "PUT")
      formData.append('if_id', this.if_id)
      formData.append('ctaf_id', this.ctaf_id)
      fetch(Helper.route('if-ctafs/'+this.if_ctaf.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        this.loading = false
        if (this.status == 200) {
          this.doesEditOpen = false
          this.if_ctafs = this.if_ctafs.map(i => i.id !== this.if_ctaf.id ? i : data.data)
          Swal.fire("IF & CTAF modifier !", "Le IF & CTAF a été modifié avec succès.", "success");
        } else {
            console.log(Helper.getError(data.data, "user_id"));
            this.errors.if_id = Helper.getError(data.data, "if_id")
            this.errors.ctaf_id = Helper.getError(data.data, "ctaf_id")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    deleteIt() {
      this.loading = true
      fetch(Helper.route('if-ctafs/'+this.if_ctaf.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(data);
        console.log("object ", this.if_ctaf);
        this.loading = false
        this.doesDeleteOpen = false
        if (this.status == 204) {
            this.if_ctafs = this.if_ctafs.filter(i => i.id !== this.if_ctaf.id)
            Swal.fire("Secteur supprimer !", "La secteur a été supprimée avec succès.", "success");
        } else {
            Swal.fire("Secteur non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
      })
    },
    loadUsers() {
      fetch(Helper.route('users?no_entity=yes'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        this.users = data.data
      })
    },
    initItem(if_ctaf, action="edit") {
        this.if_ctaf = if_ctaf
        this.initData(if_ctaf)
        if (action == "delete") {
        this.doesDeleteOpen = true
        } else {
        this.doesEditOpen = true
        }
    },
    initData(item = null) {
      this.if_id = item != null ? item.if_id : ""
      this.ctaf_id = item != null ? item.ctaf_id : ""
    }
  },
  mounted() {
    this.loadList()
    this.loadUsers()
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
      this.initData(null)
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.if_ctaf = null
        this.initData(null)
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.if_ctaf = null
        this.initData(null)
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3">
        <b-button variant="primary" @click="doesAddOpen = true" class="ms-auto">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter un nouveau
        </b-button>
    </div>
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des IF & CTAF</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>IF & CTAF</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>IF</th>
                    <th>CTAF</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(if_ctaf, i) in if_ctafs" :key="if_ctaf.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <router-link :to="'/entity/users/'+if_ctaf.if.id">{{ if_ctaf.if.lastname +" "+if_ctaf.if.firstname }}</router-link>
                    </td>
                    <td>
                      <router-link :to="'/entity/users/'+if_ctaf.ctaf.id">{{ if_ctaf.ctaf.lastname +" "+if_ctaf.ctaf.firstname }}</router-link>
                    </td>
                    <td class="hstack">
                        <b-button class="btn-sm" @click="initItem(if_ctaf)" variant="success">
                            <i
                            class="bx bx-edit font-size-16 align-middle mx-1"
                            ></i>
                        </b-button>
                        <b-button variant="danger" @click="initItem(if_ctaf, 'delete')" class="ms-2 btn-sm">
                            <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                        </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ADD MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        size="md"
        centered
        title="Ajouter"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label for="">Selectionnez l'IF</label>
                  <select required v-model="if_id" class="form-control">
                      <option value=""></option>
                      <option v-for="user in users" :key="user.id" :value="user.id">{{ user.lastname+" "+user.firstname }}</option>
                  </select>
                  <span class="text-danger mt-2" v-if="errors.if_id">{{ errors.if_id }}</span>
              </div>
              <div class="col-md-12 mb-3">
                <label for="">Selectionnez le CTAF</label>
                  <select required v-model="ctaf_id" class="form-control">
                      <option v-for="user in users" :key="user.id" :value="user.id">{{ user.lastname+" "+user.firstname }}</option>
                  </select>
                  <span class="text-danger mt-2" v-if="errors.ctaf_id">{{ errors.ctaf_id }}</span>
              </div>
            </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- EDIT MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        centered
        size="md"
        title="Modifier"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItem()">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label for="">Selectionnez l'IF</label>
                  <select required v-model="if_id" class="form-control">
                      <option value=""></option>
                      <option v-for="user in users" :key="user.id" :value="user.id">{{ user.lastname+" "+user.firstname }}</option>
                  </select>
                  <span class="text-danger mt-2" v-if="errors.if_id">{{ errors.if_id }}</span>
              </div>
              <div class="col-md-12 mb-3">
                <label for="">Selectionnez le CTAF</label>
                  <select required v-model="ctaf_id" class="form-control">
                      <option v-for="user in users" :key="user.id" :value="user.id">{{ user.lastname+" "+user.firstname }}</option>
                  </select>
                  <span class="text-danger mt-2" v-if="errors.ctaf_id">{{ errors.ctaf_id }}</span>
              </div>
            </div>
            <div class="mt-3" v-if="!loading">
              <input type="submit" value="Enregistrer" class="btn btn-primary">
            </div>
            <div class="mt-3 text-center" v-else>
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
        </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer ce IF & CTAF ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>


  </Layout>
</template>
